:root {
    --dark: #0c0906;
    --tr-dark: #00000088;
    --matte: #23190d;
    --dim: #2e2920;
    --light: #4a4436;
    --bright: #cec2a4;
    --tr-bright: #cec2a488;
}

tt {
    font-family: "Lucida Console", "Menlo", "Monaco", "Courier", monospace;
  }

@font-face {
    font-family: 'CHAMAN ELEGANT FONT';
    font-style: normal;
    font-weight: 400;
    src: local('CHAMAN ELEGANT FONT'), url('https://fonts.cdnfonts.com/s/57006/ChamanelegantfontRegular-gxv66.woff') format('woff');
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade-in {
    opacity: 0;
    animation: fadeIn 0.5s ease-in forwards;
    /* 2s fade-in effect */
}

html,
body {
    height: 100%;
}

a {
    color: whitesmoke;
}

body {
    background-color: var(--dark);
    color: var(--bright);
}

footer,
nav {
    background-color: var(--dim);
    background: linear-gradient(90deg, var(--dim) 0%, var(--dark) 100%);
    color: var(--bright);
    max-width: 1366px;
    margin-left: auto;
    margin-right: auto;
}

.console {
    color: var(--dark);
}
.console a {
    color: var(--light);
}

#root {
    position: relative;
    overflow: hidden;
    max-width: 1366px;
    margin-left: auto;
    margin-right: auto;
}

#root:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    /* background-image: url('boss-bg.jpg'); */
    background-image: url('../pages/images/aboutBg.png');
    background-size: cover;
    background-position: center;
    filter: blur(1px) brightness(0.6);
    /* Apply blur effect */
    z-index: -1;
    /* Place the image behind the content */
    background-attachment: fixed;
}

.cursor-pointer{
    cursor: pointer;
}

.logo {
    height: 64px;
    cursor: pointer;
    font-size: 14px;
}

footer .logo {
    height: 32px;
    cursor: pointer;
}

.nav-link {
    color: var(--bright) !important;
}

#navbarMenu {
    text-align: center;
}

.navbar-collapse {
    flex-grow: 0 !important;
}

.home-link:hover {
    background-size: 0 3px, 100% 3px;
}

.home-link {
    cursor: pointer;
    background:
        linear-gradient(to right,
            rgba(100, 200, 200, 0),
            rgba(100, 200, 200, 0)),
        linear-gradient(to right,
            var(--bright),
            var(--dim),
            var(--dark));
    background-size: 100% 3px, 0 3px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms color 0.3s;
}

.navbar-toggler {
    border: 1px solid var(--light) !important;
}

.video-background {
    position: relative;
    width: 100%;
    height: 90vh;
    /*Adjust as needed */
    overflow: hidden;
}

.background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 95%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
    filter: brightness(0.8) contrast(1.1);
}

.home-content {
    position: relative;
    z-index: 1;
    text-align: center;
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.9);
}

.nav-item:hover{
    background-color: var(--tr-bright);
    color: var(--dark) !important;
    border-color: var(--tr-dark);
    transition: background-color 0.5s ease, color 0.5s ease;
}

.btn-bright {
    background-color: var(--bright);
    color: var(--dark);
}

.btn-dark {
    background-color: var(--tr-dark);
    color: var(--bright);
    border-color: var(--bright);
}
.btn-dark:hover {
    background-color: var(--bright);
    color: var(--dark);
    border-color: var(--tr-dark);
    transition: background-color 0.5s ease, color 0.5s ease;
}

.signin-welcome {
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.9);

}
.signin-welcome:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: brightness(0.2) contrast(1);
    background: url('../pages/images/loginSide.jpg') no-repeat center/cover;
    z-index: -1;
}

/*
#about-main:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../pages/images/aboutBg.png');
    background-size: cover;
    background-position: center;
    filter: blur(1px) brightness(0.8);
    z-index: -1;
    background-attachment: fixed;
}
*/
.glow-link {
    position: relative;
    background-color: transparent;
    transition: background-color 0.5s ease;
    overflow: hidden;
    /* To ensure the pseudo-element doesn't overflow */
}

.glow-link::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 80%;
    background-color: #fff0;
    background-image: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.25) 0%, rgba(0, 0, 0, 0.0) 75%);
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.5s ease;
    pointer-events: none;
    /* Ensures the pseudo-element doesn't interfere with content */
    z-index: 0;
}

.glow-link:hover::before {
    opacity: 1;
}

.text-chaman {
    font-family: 'CHAMAN ELEGANT FONT', sans-serif;
}

.each-slide-effect>div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
}


.each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
}

.bg-tr-dark {
    background-color: var(--tr-dark);
    color: var(--bright)
}

#bc-table .list-group-item {
    background-color: transparent;
    font-size: 20px;
    color: var(--bright)
}

.md-body a {
    color: var(--dark);
}

.md-body h1 {
    text-align: center;
}
.md-body code {
    color: var(--dark);
    /*background-color: yellow; */
    padding: 3px;;
}

.negative {
    filter: invert(1);
}

@media (max-width: 767.98px) {

    /* For small screens (sm) and below */
    .each-slide-effect>div {
        height: 200px;
    }

    #bc-table .list-group-item {
        font-size: 14px;
        padding-left: 0;
        padding-right: 0;
        white-space: nowrap;
    }

    #bc-table .text-end .list-group-item {
        font-size: 14px;
        padding-left: 0;
        padding-right: 3px;
    }

    #bc-table .card-title {
        font-size: 15px;
        font-weight: bold;
        white-space: nowrap;
        padding-left: 0;
        padding-right: 0;
    }
}