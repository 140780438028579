.text-gold {
  background: linear-gradient(to bottom, #e6cb36 27%, #ffecb3 40%, #3a2c0f 78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  text-transform: uppercase;
  /*font-size: calc(18vw + .5rem);*/
  margin: 0;
  font-weight: 400;
  -webkit-text-stroke: 1px #ffecb3bb;

}

.text-gold::after {
  background: none;
  content: attr(data-heading) / "";
  left: 0;
  top: 0;
  z-index: -1;
  position: absolute;
  text-shadow:
    -1px 0 1px #c6bb9f,
    0 1px 1px #c6bb9f,
    5px 5px 10px rgba(0, 0, 0, 0.4),
    -5px -5px 10px rgba(0, 0, 0, 0.4);
}

.text-silver {
  background: linear-gradient(to bottom, #d4d4d4 27%, #ffffff 40%, #3a3a3a 78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  text-transform: uppercase;
  /*font-size: calc(18vw + .5rem);*/
  margin: 0;
  font-weight: 400;
  -webkit-text-stroke: 1px #ffffffbb;

}

.text-silver::after {
  background: none;
  content: attr(data-heading) / "";
  left: 0;
  top: 0;
  z-index: -1;
  position: absolute;
  text-shadow:
    -1px 0 1px #c6c6c6,
    0 1px 1px #c6c6c6,
    5px 5px 10px rgba(0, 0, 0, 0.4),
    -5px -5px 10px rgba(0, 0, 0, 0.4);
}

.text-bronze {
  background: linear-gradient(to bottom, #684700 27%, #ccba85 40%, #3a2c0f 78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  text-transform: uppercase;
  /*font-size: calc(18vw + .5rem);*/
  margin: 0;
  font-weight: 400;
  -webkit-text-stroke: 1px #ccba85bb;
}

.text-bronze::after {
  background: none;
  content: attr(data-heading) / "";
  left: 0;
  top: 0;
  z-index: -1;
  position: absolute;
  text-shadow:
    -1px 0 1px #5a5037,
    0 1px 1px #5a5037,
    5px 5px 10px rgba(0, 0, 0, 0.4),
    -5px -5px 10px rgba(0, 0, 0, 0.4);
}

.button-cust {
  display: inline-block;
  margin: 0.3em;
  padding: 0.8em 1.3em;
  width: 10em;
  text-align: center;
  letter-spacing: 0.2em;
  font-size: 1em;
}

.button-gold {
  color: rgba(0, 0, 0, 0.72);
  text-decoration: none;
  font-weight: bold;
  text-shadow: 1px 1px 0 #ffffff;
  box-shadow:
    2px 2px 0.5em rgba(122, 98, 0, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.34);
  border: 1px solid #deca73;
  background:
    -moz-linear-gradient(-72deg,
      #ffde45,
      #ffffff 16%,
      #ffde45 21%,
      #ffffff 24%,
      #452100 1%,
      #ffde45 36%,
      #ffffff 45%,
      #ffffff 60%,
      #ffde45 72%,
      #ffffff 80%,
      #ffde45 84%,
      #452100);
  background:
    -webkit-linear-gradient(-72deg,
      #ffde45,
      #ffffff 16%,
      #ffde45 21%,
      #ffffff 24%,
      #452100 27%,
      #ffde45 36%,
      #ffffff 45%,
      #ffffff 60%,
      #ffde45 72%,
      #ffffff 80%,
      #ffde45 84%,
      #452100);
  background:
    -o-linear-gradient(-72deg,
      #ffde45,
      #ffffff 16%,
      #ffde45 21%,
      #ffffff 24%,
      #452100 27%,
      #ffde45 36%,
      #ffffff 45%,
      #ffffff 60%,
      #ffde45 72%,
      #ffffff 80%,
      #ffde45 84%,
      #452100);
  background:
    linear-gradient(-72deg,
      #ffde45,
      #ffffff 16%,
      #ffde45 21%,
      #ffffff 24%,
      #452100 27%,
      #ffde45 36%,
      #ffffff 45%,
      #ffffff 60%,
      #ffde45 72%,
      #ffffff 80%,
      #ffde45 84%,
      #452100);
}

.button-silver {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  font-weight: bold;
  text-shadow: 1px 1px 0 #ffffff;
  box-shadow:
    2px 2px 0.5em rgba(122, 122, 122, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.34);
  border: 1px solid #dedede;
  background:
    -moz-linear-gradient(-72deg,
      #dedede,
      #ffffff 16%,
      #dedede 21%,
      #ffffff 24%,
      #454545 27%,
      #dedede 36%,
      #ffffff 45%,
      #ffffff 60%,
      #dedede 72%,
      #ffffff 80%,
      #dedede 84%,
      #a1a1a1);
  background:
    -webkit-linear-gradient(-72deg,
      #dedede,
      #ffffff 16%,
      #dedede 21%,
      #ffffff 24%,
      #454545 27%,
      #dedede 36%,
      #ffffff 45%,
      #ffffff 60%,
      #dedede 72%,
      #ffffff 80%,
      #dedede 84%,
      #a1a1a1);
  background:
    -o-linear-gradient(-72deg,
      #dedede,
      #ffffff 16%,
      #dedede 21%,
      #ffffff 24%,
      #454545 27%,
      #dedede 36%,
      #ffffff 45%,
      #ffffff 60%,
      #dedede 72%,
      #ffffff 80%,
      #dedede 84%,
      #a1a1a1);
  background:
    linear-gradient(-72deg,
      #dedede,
      #ffffff 16%,
      #dedede 21%,
      #ffffff 24%,
      #454545 27%,
      #dedede 36%,
      #ffffff 45%,
      #ffffff 60%,
      #dedede 72%,
      #ffffff 80%,
      #dedede 84%,
      #a1a1a1);
}

.button-bronze {
  color: rgba(45, 00, 0, 0.55);
  text-decoration: none;
  font-weight: bold;
  text-shadow: 1px 1px 0 #dea173;
  box-shadow:
    2px 2px 0.5em rgba(122, 55, 34, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #dea173;
  background:
    -moz-linear-gradient(-72deg,
      #ca7345,
      #ffdeca 16%,
      #ca7345 21%,
      #ffdeca 24%,
      #a14521 27%,
      #ca7345 36%,
      #ffdeca 45%,
      #ffdeca 60%,
      #ca7345 72%,
      #ffdeca 80%,
      #ca7345 84%,
      #732100);
  background:
    -webkit-linear-gradient(-72deg,
      #ca7345,
      #ffdeca 16%,
      #ca7345 21%,
      #ffdeca 24%,
      #a14521 27%,
      #ca7345 36%,
      #ffdeca 45%,
      #ffdeca 60%,
      #ca7345 72%,
      #ffdeca 80%,
      #ca7345 84%,
      #732100);
  background:
    -o-linear-gradient(-72deg,
      #ca7345,
      #ffdeca 16%,
      #ca7345 21%,
      #ffdeca 24%,
      #a14521 27%,
      #ca7345 36%,
      #ffdeca 45%,
      #ffdeca 60%,
      #ca7345 72%,
      #ffdeca 80%,
      #ca7345 84%,
      #732100);
  background:
    linear-gradient(-72deg,
      #ca7345,
      #ffdeca 16%,
      #ca7345 21%,
      #ffdeca 24%,
      #a14521 27%,
      #ca7345 36%,
      #ffdeca 45%,
      #ffdeca 60%,
      #ca7345 72%,
      #ffdeca 80%,
      #ca7345 84%,
      #732100);
}

.button-platinum {
  color: rgba(0, 0, 0, 0.72);
  text-decoration: none;
  font-weight: bold;
  text-shadow: 1px 1px 0 #ffffff;
  box-shadow:
    2px 2px 0.5em rgba(122, 122, 122, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #cacade;
  background:
    -moz-linear-gradient(-72deg,
      #dedeff,
      #ffffff 16%,
      #dedeff 21%,
      #ffffff 24%,
      #555564 27%,
      #dedeff 36%,
      #ffffff 45%,
      #ffffff 60%,
      #dedeff 72%,
      #ffffff 80%,
      #dedeff 84%,
      #555564);
  background:
    -webkit-linear-gradient(-72deg,
      #dedeff,
      #ffffff 16%,
      #dedeff 21%,
      #ffffff 24%,
      #555564 27%,
      #dedeff 36%,
      #ffffff 45%,
      #ffffff 60%,
      #dedeff 72%,
      #ffffff 80%,
      #dedeff 84%,
      #555564);
  background:
    -o-linear-gradient(-72deg,
      #dedeff,
      #ffffff 16%,
      #dedeff 21%,
      #ffffff 24%,
      #555564 27%,
      #dedeff 36%,
      #ffffff 45%,
      #ffffff 60%,
      #dedeff 72%,
      #ffffff 80%,
      #dedeff 84%,
      #555564);
  background:
    linear-gradient(-72deg,
      #dedeff,
      #ffffff 16%,
      #dedeff 21%,
      #ffffff 24%,
      #555564 27%,
      #dedeff 36%,
      #ffffff 45%,
      #ffffff 60%,
      #dedeff 72%,
      #ffffff 80%,
      #dedeff 84%,
      #555564);
}

.button-pinkgold {
  color: rgba(56, 13, 0, 0.64);
  text-decoration: none;
  font-weight: bold;
  text-shadow: 1px 1px 0 #ffffff;
  box-shadow:
    2px 2px 0.5em rgba(122, 98, 55, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #caa145;
  background:
    -moz-linear-gradient(-72deg,
      #ffdeca,
      #ffffff 16%,
      #ffdeca 21%,
      #ffffff 24%,
      #de7345 27%,
      #ffdeca 36%,
      #ffffff 45%,
      #ffffff 60%,
      #ffdeca 72%,
      #ffffff 80%,
      #ffdeca 84%,
      #de7345);
  background:
    -webkit-linear-gradient(-72deg,
      #ffdeca,
      #ffffff 16%,
      #ffdeca 21%,
      #ffffff 24%,
      #de7345 27%,
      #ffdeca 36%,
      #ffffff 45%,
      #ffffff 60%,
      #ffdeca 72%,
      #ffffff 80%,
      #ffdeca 84%,
      #de7345);
  background:
    -o-linear-gradient(-72deg,
      #ffdeca,
      #ffffff 16%,
      #ffdeca 21%,
      #ffffff 24%,
      #de7345 27%,
      #ffdeca 36%,
      #ffffff 45%,
      #ffffff 60%,
      #ffdeca 72%,
      #ffffff 80%,
      #ffdeca 84%,
      #de7345);
  background:
    linear-gradient(-72deg,
      #ffdeca,
      #ffffff 16%,
      #ffdeca 21%,
      #ffffff 24%,
      #de7345 27%,
      #ffdeca 36%,
      #ffffff 45%,
      #ffffff 60%,
      #ffdeca 72%,
      #ffffff 80%,
      #ffdeca 84%,
      #de7345);
}

.button-whitegold {
  color: rgba(82, 82, 34, 0.72);
  text-decoration: none;
  font-weight: bold;
  text-shadow: 1px 1px 0 #ffdeca;
  box-shadow:
    2px 2px 0.5em rgba(155, 122, 89, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #caa173;
  background:
    -moz-linear-gradient(-72deg,
      #ffdea1,
      #ffffff 16%,
      #ffdea1 21%,
      #ffffff 24%,
      #736445 27%,
      #ffdea1 36%,
      #ffffff 45%,
      #ffffff 60%,
      #ffdea1 72%,
      #ffffff 80%,
      #ffdea1 84%,
      #736445);
  background:
    -webkit-linear-gradient(-72deg,
      #ffdea1,
      #ffffff 16%,
      #ffdea1 21%,
      #ffffff 24%,
      #736445 27%,
      #ffdea1 36%,
      #ffffff 45%,
      #ffffff 60%,
      #ffdea1 72%,
      #ffffff 80%,
      #ffdea1 84%,
      #736445);
  background:
    -o-linear-gradient(-72deg,
      #ffdea1,
      #ffffff 16%,
      #ffdea1 21%,
      #ffffff 24%,
      #736445 27%,
      #ffdea1 36%,
      #ffffff 45%,
      #ffffff 60%,
      #ffdea1 72%,
      #ffffff 80%,
      #ffdea1 84%,
      #736445);
  background:
    linear-gradient(-72deg,
      #ffdea1,
      #ffffff 16%,
      #ffdea1 21%,
      #ffffff 24%,
      #736445 27%,
      #ffdea1 36%,
      #ffffff 45%,
      #ffffff 60%,
      #ffdea1 72%,
      #ffffff 80%,
      #ffdea1 84%,
      #736445);
}

.button-yellowgold {
  color: rgba(73, 45, 21, 0.72);
  text-decoration: none;
  font-weight: bold;
  text-shadow: 1px 1px 0 #ffffff;
  box-shadow:
    2px 2px 0.5em rgba(155, 122, 89, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #deca45;
  background:
    -moz-linear-gradient(-72deg,
      #ffc373,
      #ffffff 16%,
      #ffc373 21%,
      #ffffff 24%,
      #a17434 27%,
      #ffc373 36%,
      #ffffff 45%,
      #ffffff 60%,
      #ffc373 72%,
      #ffffff 80%,
      #ffc373 84%,
      #a17434);
  background:
    -webkit-linear-gradient(-72deg,
      #ffc373,
      #ffffff 16%,
      #ffc373 21%,
      #ffffff 24%,
      #a17434 27%,
      #ffc373 36%,
      #ffffff 45%,
      #ffffff 60%,
      #ffc373 72%,
      #ffffff 80%,
      #ffc373 84%,
      #a17434);
  background:
    -o-linear-gradient(-72deg,
      #ffc373,
      #ffffff 16%,
      #ffc373 21%,
      #ffffff 24%,
      #a17434 27%,
      #ffc373 36%,
      #ffffff 45%,
      #ffffff 60%,
      #ffc373 72%,
      #ffffff 80%,
      #ffc373 84%,
      #a17434);
  background:
    linear-gradient(-72deg,
      #ffc373,
      #ffffff 16%,
      #ffc373 21%,
      #ffffff 24%,
      #a17434 27%,
      #ffc373 36%,
      #ffffff 45%,
      #ffffff 60%,
      #ffc373 72%,
      #ffffff 80%,
      #ffc373 84%,
      #a17434);
}

.button-perl {
  color: #e9e9e9;
  text-decoration: none;
  font-weight: bold;
  text-shadow:
    1px 1px 0 #ffffff,
    -1px -1px 0 #b7b7b7;
  box-shadow:
    2px 2px 0.5em rgba(98, 73, 122, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.75),
    inset -1px -1px 0 rgba(0, 0, 0, 0.34);
  border: 1px solid #cacaca;
  background:
    -moz-linear-gradient(-72deg,
      #dedede,
      #ffffff 16%,
      #dedede 21%,
      #ffffff 24%,
      #caa1de 27%,
      #dea1ca 30%,
      #dedede 38%,
      #ffffff 45%,
      #ffffff 60%,
      #dedede 72%,
      #ffffff 80%,
      #dedede 84%,
      #caa1de 93%,
      #dea1ca);
  background:
    -webkit-linear-gradient(-72deg,
      #dedede,
      #ffffff 16%,
      #dedede 21%,
      #ffffff 24%,
      #caa1de 27%,
      #dea1ca 30%,
      #dedede 38%,
      #ffffff 45%,
      #ffffff 60%,
      #dedede 72%,
      #ffffff 80%,
      #dedede 84%,
      #caa1de 93%,
      #dea1ca);
  background:
    -o-linear-gradient(-72deg,
      #dedede,
      #ffffff 16%,
      #dedede 21%,
      #ffffff 24%,
      #caa1de 27%,
      #dea1ca 30%,
      #dedede 38%,
      #ffffff 45%,
      #ffffff 60%,
      #dedede 72%,
      #ffffff 80%,
      #dedede 84%,
      #caa1de 93%,
      #dea1ca);
  background:
    linear-gradient(-72deg,
      #dedede,
      #ffffff 16%,
      #dedede 21%,
      #ffffff 24%,
      #caa1de 27%,
      #dea1ca 30%,
      #dedede 38%,
      #ffffff 45%,
      #ffffff 60%,
      #dedede 72%,
      #ffffff 80%,
      #dedede 84%,
      #caa1de 93%,
      #dea1ca);
}

.button-china {
  color: #dedede;
  text-decoration: none;
  font-weight: bold;
  text-shadow: 1px 1px 0 #ffffff, -1px -1px 0 #a1a1a1;
  box-shadow:
    2px 2px 0.5em rgba(155, 155, 155, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.21);
  border: 1px solid #cacaca;
  background:
    -moz-linear-gradient(#ffffff,
      #e3e3e3);
  background:
    -webkit-linear-gradient(#ffffff,
      #e3e3e3);
  background:
    -o-linear-gradient(#ffffff,
      #e3e3e3);
  background:
    linear-gradient(#ffffff,
      #e3e3e3);
}

.button-woody {
  color: rgba(73, 21, 0, 0.7);
  text-decoration: none;
  font-weight: bold;
  text-shadow: 1px 1px 0 #ffffff;
  box-shadow:
    2px 2px 0.5em rgba(155, 121, 34, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #a16421;
  background:
    -moz-linear-gradient(#ca7321,
      #ffcaa1 15%,
      #dea173 20%,
      #a16421 15%,
      #ca7321 30%,
      #ffcaa1 35%,
      #ca7321 40%,
      #ffcaa1 50%,
      #a16421 55%,
      #ca7321 60%,
      #ffcaa1 65%,
      #ca7321 75%,
      #dea173 80%,
      #ffcaa1 90%,
      #dea173 80%,
      #ca7321);
  background:
    -webkit-linear-gradient(#ca7321,
      #ffcaa1 15%,
      #dea173 20%,
      #a16421 15%,
      #ca7321 30%,
      #ffcaa1 35%,
      #ca7321 40%,
      #ffcaa1 50%,
      #a16421 55%,
      #ca7321 60%,
      #ffcaa1 65%,
      #ca7321 75%,
      #dea173 80%,
      #ffcaa1 90%,
      #dea173 80%,
      #ca7321);
  background:
    -o-linear-gradient(#ca7321,
      #ffcaa1 15%,
      #dea173 20%,
      #a16421 15%,
      #ca7321 30%,
      #ffcaa1 35%,
      #ca7321 40%,
      #ffcaa1 50%,
      #a16421 55%,
      #ca7321 60%,
      #ffcaa1 65%,
      #ca7321 75%,
      #dea173 80%,
      #ffcaa1 90%,
      #dea173 80%,
      #ca7321);
  background:
    linear-gradient(#ca7321,
      #ffcaa1 15%,
      #dea173 20%,
      #a16421 15%,
      #ca7321 30%,
      #ffcaa1 35%,
      #ca7321 40%,
      #ffcaa1 50%,
      #a16421 55%,
      #ca7321 60%,
      #ffcaa1 65%,
      #ca7321 75%,
      #dea173 80%,
      #ffcaa1 90%,
      #dea173 80%,
      #ca7321);
}

.button-carbon {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  font-weight: bold;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.45);
  border: 1px solid #565656;
  box-shadow:
    2px 2px 0.5em rgba(0, 0, 0, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.55),
    inset -1px -1px 0 rgba(0, 0, 0, 0.5);
  background:
    -moz-repeating-linear-gradient(top, #565656, #131313 2px, #565656 1px);
  background:
    -o-repeating-linear-gradient(top, #565656, #131313 2px, #565656 1px);
  background:
    -webkit-repeating-linear-gradient(top, #565656, #131313 2px, #565656 1px);
  background:
    repeating-linear-gradient(top, #565656, #131313 2px, #565656 1px);
}