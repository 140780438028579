.container-signup {
  width: 100%;
  max-width: 600px;
  padding: 15px;
  margin: auto;
}

.container-signup .form-label {
  margin-bottom: 0;
}
