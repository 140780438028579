/* CREDIT: https://getbootstrap.com/docs/5.1/examples/sign-in/signin.css */

html,
body {
  height: 100%;
}

body {
  align-items: center;
}

.container-auth {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.container-auth .checkbox {
  font-weight: 400;
}

.container-auth .form-floating:focus-within {
  z-index: 2;
}

.container-auth .form-input-top {
  margin-bottom: -1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.container-auth .form-input-middle {
  margin-bottom: -1px;
  border-radius: 0;
}

.container-auth .form-input-bottom {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.auth-icon {
  color: #7713F7;
  font-size: 4rem;
}
